:root {
  --tn-blue: #3f47ec;
  --tn-red: #d13e33;
  --tn-green: #48e5c2;
  --tn-light-grey: #e9ecef;
  --tn-dark-grey: #a2a2a2;
  --tn-black: #212529;
  --tn-white: #ffffff;
}

body {
  font-family: 'Poppins', sans-serif;
  // font-size: 1.01em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

strong {
  font-weight: 600;
}

p {
  font-weight: 400;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: var(--tn-light-grey);
  color: var(--tn-black);
}

[class*='text-highlight-'] {
  background: left 1em/1em 0.2em;
}

.hero-display {
  font-size: 3.3em;

  strong {
    font-weight: 800;
  }
}

.benefits-text {
  font-size: 1.15em;
}

.landing-page-btn {
  font-size: 1.15em;
  min-width: 200px;
  padding: 0.375rem 0.95rem;
}

.landing-page-copy {
  font-size: 1.8em;
}

.badge-usage {
  padding: 0.6em 1.2em;
}

.usage-header {
  padding: 0.5em 0.5em 0.1em 0.5em;
}

.usage-item {
  margin-bottom: 1em;
  padding: 0 0.5em;
  font-size: 1.05em;
}

.btn-auth {
  min-width: 150px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--tn-light-grey);
}

.text-blue {
  color: var(--tn-blue);
}

.text-black {
  color: var(--tn-black);
  text-decoration: underline;
}

.text-red {
  color: var(--tn-red);
}

.landing-price-card {
  min-width: 220px;
  margin-bottom: 0.8em !important;
}

.price-card {
  cursor: pointer;
  min-width: 220px;
  margin-bottom: 0.8em !important;
}

.price-card-disabled {
  cursor: not-allowed !important;
}

.price-group-item {
  padding-left: 0;
  font-size: 0.8em;
}

.price-card-border {
  border: 1px solid var(--tn-blue);
}

.card-details {
  margin-bottom: 0.5rem;
}

.play-highlight {
  color: rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: var(--tn-blue);
    color: var(--tn-white);
  }
}

.paragraph {
  font-size: 1.02em;
  margin-bottom: 0.5em;
  background-color: var(--tn-white);
  padding: 0.3em 0.5em;
}

.time {
  font-size: 0.9em;
}

.lead {
  font-size: 1.3em;
}

.bold {
  font-weight: 700;
}

.divider {
  height: 0;
  margin: 1rem 0;
  overflow: hidden;
  border-top: 1px solid var(--tn-light-grey);
}

.box {
  font-size: 1.15rem;
  background-color: #fff;
  position: relative;
  padding: 30px 15px;
}

.box.disabled {
  background-color: var(--tn-light-grey);
}

.box__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.box__dragndrop {
  display: none;
}

.box.is-uploading .box__input {
  visibility: none;
}

.box.has-advanced-upload {
  outline: 2px dashed rgba(0, 0, 0, 0.125);
  outline-offset: -10px;
}
.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: var(--tn-blue);
  color: var(--tn-white);
  outline: 2px dashed var(--tn-white);
}

.files-list {
  max-height: 55vh;
  overflow: scroll;
}

.translation-list {
  max-height: 30vh;
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(250, 250, 250, 0.65);
}

.lds-ring {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1050;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 70px;
  height: 70px;
  margin: 6px;
  border: 6px solid #2e27f8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2e27f8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-select-custom {
  height: calc(1.5em + 1rem + 2px);
  min-width: 200px;
  z-index: 2;
}

.react-select-lang {
  width: 320px;
}

.react-select__control {
  border-radius: 0.125em !important;
  // height: calc(1.5em + 1rem + 2px);
}

.title {
  font-size: 3.5em;
}

.sticky-top-controls {
  top: 50px;
}

.custom-form-group {
  label {
    font-size: 0.95em;
    font-weight: bold;
  }
}

.arrow-none:after {
  display: none;
}

.notification-list .noti-icon-badge {
  display: inline-block;
  margin-bottom: 9px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: var(--tn-red);
}

.notification-list-menu {
  max-height: 30vh;
  overflow: scroll;
}

.opaque {
  color: rgba(0, 0, 0, 0.5);
}

.w-60 {
  width: 60% !important;
}

.translation-link {
  color: var(--tn-black);

  &:hover {
    color: var(--tn-blue);
    text-decoration: underline;
  }
}
