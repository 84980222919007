$theme-colors: (
  'primary': #3f47ec,
  'danger': #d13e33,
  'success': #48e5c2,
  'warning': #ffe787
);

@import '../../../node_modules/bootstrap/scss/bootstrap';

.supported-languages {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

dl {
  margin-bottom: 0.5em;
}

.btn {
  line-height: 1.7;
  vertical-align: baseline;
}

.card {
  border-radius: 0.125em;
}

dt {
  font-weight: 600;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.custom-select {
  font-size: 1.1em;
}

.custon-form-group {
  margin-bottom: 0;
}

.dropdown-menu {
  font-size: 0.95em;
}

.form-control {
  border-radius: 0.125em;
  // height: calc(1.5em + 1rem + 2px);
}

.modal-content {
  border-radius: 0.125em;
}

.navbar {
  padding: 0.4em 1em;
}

.navbar-brand {
  margin-right: 0 !important;
}

.navbar-light .navbar-nav .active {
  font-weight: 800;
}

.navbar-light .navbar-nav .active > .nav-link > span {
  text-decoration: underline;
}

.react-datepicker__day--selected {
  background-color: #3f47ec !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: lighten(#3f47ec, 5%) !important;
}

.table th,
.table td {
  vertical-align: middle;
}

.table thead th {
  border-bottom: none;
  font-size: 0.95em;
}

.toast {
  background-color: var(--tn-white);
  font-size: 0.9em;
  min-width: 220px;
  padding: 0.5em;
}

.toast-header {
  color: var(--tn-black);
  font-size: 1.1em;
}
